<template>
  <div style="width:100%;float:left;">
    <CButton size="sm" color="primary" class="mb-2" @click="$router.push('/locations')">Back</CButton>
    <CCard>
      <CCardHeader>Location Styles</CCardHeader>
      <CCardBody>
        <div style="width:100%;float:left;margin-bottom:15px;">
          <v-select v-model="addStyle" style="width:250px;float:left;" placeholder="Select Style To Add" label="customer_style" :options="availableStyles"></v-select>
          <CButton style="float:left;margin-top:2px;margin-left:10px;" size="sm" @click="submitStyle()" color="success">Submit Style</CButton>
        </div>
        <CDataTable
          hover
          v-if="!loading"
          :items="locationStyles"
          :fields="fields"
          :items-per-page="10"
          pagination
        >
          <template #remove="{item, index}">
            <td>
              <CButton color="danger" size="sm" @click="remove( item, index )">Remove</CButton>
            </td>
          </template>
        </CDataTable>
        <small-loader v-else></small-loader>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
var methods = {}

methods.getData = function () {
  this.loading = true
  const postData = {
    id: this.id
  }
  axios.post(this.$apiAdress + '/api/locations/getLocationStyles?token=' + localStorage.getItem('api_token'), postData).then(response => {
    // this.data = response.data
    console.log(response)
    this.locationStyles = response.data.locationStyles
    this.availableStyles = response.data.availableStyles
    this.loading = false
  })
}

methods.submitStyle = function () {
  this.loading = true
  const postData = {
    style: this.addStyle,
    location_id: this.id
  }
  axios.post(this.$apiAdress + '/api/locations/submitStyle?token=' + localStorage.getItem('api_token'), postData).then(response => {
    // this.data = response.data
    console.log(response)
    this.locationStyles.push(response.data)
    this.loading = false
  })
}

methods.remove = function (style, index) {
  this.loading = true
  const postData = {
    style: style,
    id: this.id
  }
  console.log(postData)
  axios.post(this.$apiAdress + '/api/locations/removeStyle?token=' + localStorage.getItem('api_token'), postData).then(response => {
    // this.data = response.data
    console.log(response)
    // this.locationStyles.push(response.data)
    this.locationStyles.splice(index, 1)
    this.loading = false
  })
}

export default {
    // ===Component name
    name: "EditLocationStyles",
    // ===Props passed to component
    props: ['id'],
    // ===Components used by this component
    components: {

    },
    // ====component Data properties
    data(){
        return{
          locationStyles: [],
          loading: true,
          addStyle: '',
          availableStyles: [],
          fields: ['customer_style', 'remove']
        }
    },
    // ===Code to be executed when Component is mounted
    mounted() {
      this.getData()
    },
    // ===Computed properties for the component
    computed: {},
    // ===Component methods
    methods: methods
}
</script>
<!-- styles -->
<!-- adding scoped attribute will apply the css to this component only -->
<style scoped></style>
